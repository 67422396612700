import React from 'react';
import {
  Phone,
  Users,
  BarChart,
  Heart,
  DollarSign,
  Handshake,
  HeadphonesIcon,
  ChevronRight,
  Calendar,
  Building2
} from 'lucide-react';
import ServiceCard from './components/ServiceCard';
import Testimonial from './components/Testimonial';
import ContactForm from './components/ContactForm';

function App() {
  const services = [
    {
      title: 'Political Polling',
      description: 'Expert political survey campaigns with proven success for major organizations like the DNC.',
      Icon: Users
    },
    {
      title: 'Healthcare Outreach',
      description: 'Specialized patient engagement programs improving healthcare outcomes and compliance.',
      Icon: Heart
    },
    {
      title: 'Market Research',
      description: 'Comprehensive market analysis and consumer insight gathering for informed decision-making.',
      Icon: BarChart
    },
    {
      title: 'Financial Services',
      description: 'Professional customer support for banking, insurance, and investment firms.',
      Icon: DollarSign
    },
    {
      title: 'Nonprofit Fundraising',
      description: 'Dedicated campaigns to support charitable causes and community initiatives.',
      Icon: Handshake
    },
    {
      title: 'Tech Support',
      description: '24/7 technical assistance and problem resolution for your customers.',
      Icon: HeadphonesIcon
    }
  ];

  return (
    <div className="min-h-screen bg-slate-900">
      {/* Hero Section */}
      <header className="bg-gradient-to-r from-indigo-900 to-purple-900 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 md:py-24">
          <div className="max-w-3xl">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 md:mb-6 leading-tight">
              Transform Your Customer Engagement with Expert Call Center Solutions
            </h1>
            <p className="text-lg md:text-xl mb-6 md:mb-8 text-slate-200">
              Trusted by the Democratic National Committee and leading healthcare providers for high-stakes campaigns and superior customer service.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <a
                href="#contact"
                className="w-full sm:w-auto text-center inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition-colors"
              >
                Get Free Consultation
                <ChevronRight className="ml-2 w-5 h-5" />
              </a>
              <a
                href="#schedule"
                className="w-full sm:w-auto text-center inline-flex items-center justify-center px-6 py-3 border border-slate-300 text-base font-medium rounded-md text-white hover:bg-slate-800 transition-colors"
              >
                <Calendar className="mr-2 w-5 h-5" />
                Schedule a Call
              </a>
            </div>
          </div>
        </div>
      </header>

      {/* Stats Section */}
      <section className="py-8 md:py-12 bg-slate-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 md:gap-8 text-center">
            <div className="p-4 md:p-6">
              <p className="text-3xl md:text-4xl font-bold text-indigo-400">1M+</p>
              <p className="mt-2 text-slate-300">Calls Handled</p>
            </div>
            <div className="p-4 md:p-6">
              <p className="text-3xl md:text-4xl font-bold text-indigo-400">98%</p>
              <p className="mt-2 text-slate-300">Client Satisfaction</p>
            </div>
            <div className="p-4 md:p-6">
              <p className="text-3xl md:text-4xl font-bold text-indigo-400">50+</p>
              <p className="mt-2 text-slate-300">Enterprise Clients</p>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-12 md:py-16 bg-slate-900" id="services">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8 md:mb-12">
            <h2 className="text-2xl md:text-3xl font-bold text-white mb-3 md:mb-4">Our Services</h2>
            <p className="text-lg md:text-xl text-slate-300">Comprehensive call center solutions tailored to your needs</p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            {services.map((service, index) => (
              <ServiceCard key={index} {...service} />
            ))}
          </div>
        </div>
      </section>

      {/* Success Stories Section */}
      <section className="py-12 md:py-16 bg-slate-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8 md:mb-12">
            <h2 className="text-2xl md:text-3xl font-bold text-white mb-3 md:mb-4">Success Stories</h2>
            <p className="text-lg md:text-xl text-slate-300">Real results for real clients</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
            <div className="bg-slate-700 p-6 md:p-8 rounded-lg">
              <Building2 className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl md:text-2xl font-bold mb-4 text-white">Democratic National Committee</h3>
              <p className="text-slate-300 mb-4">
                Managed large-scale political survey campaigns, achieving a 45% response rate and gathering crucial voter insights that informed strategic decisions.
              </p>
              <ul className="list-disc list-inside text-slate-300 space-y-2">
                <li>200,000+ surveys completed</li>
                <li>98% data accuracy rate</li>
                <li>45% response rate</li>
              </ul>
            </div>
            <div className="bg-slate-700 p-6 md:p-8 rounded-lg">
              <Heart className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl md:text-2xl font-bold mb-4 text-white">Healthcare Provider Network</h3>
              <p className="text-slate-300 mb-4">
                Implemented patient engagement program resulting in improved appointment attendance and medication adherence.
              </p>
              <ul className="list-disc list-inside text-slate-300 space-y-2">
                <li>35% reduction in missed appointments</li>
                <li>40% improvement in medication adherence</li>
                <li>92% patient satisfaction rate</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-12 md:py-16 bg-slate-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8 md:mb-12">
            <h2 className="text-2xl md:text-3xl font-bold text-white mb-3 md:mb-4">Client Testimonials</h2>
            <p className="text-lg md:text-xl text-slate-300">What our clients say about us</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
            <Testimonial
              quote="1028 Ventures transformed our voter outreach efforts. Their team's dedication and expertise helped us achieve unprecedented engagement rates."
              author="Sarah Johnson"
              role="Campaign Director"
              company="DNC"
            />
            <Testimonial
              quote="The healthcare outreach program exceeded our expectations. Patient satisfaction improved significantly, and we saw real results in compliance rates."
              author="Dr. Michael Chen"
              role="Chief Medical Officer"
              company="Healthcare Network"
            />
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-12 md:py-16 bg-slate-800" id="contact">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 items-center">
            <div>
              <h2 className="text-2xl md:text-3xl font-bold text-white mb-3 md:mb-4">Get Started Today</h2>
              <p className="text-lg md:text-xl text-slate-300 mb-6">
                Transform your customer engagement with our expert call center solutions. Schedule a free consultation to discuss your needs.
              </p>
              <div className="space-y-4">
                <div className="flex items-center">
                  <Phone className="w-6 h-6 text-indigo-400 mr-3" />
                  <span className="text-slate-300">Call us: (888) 555-0123</span>
                </div>
                <div className="flex items-center">
                  <Calendar className="w-6 h-6 text-indigo-400 mr-3" />
                  <span className="text-slate-300">Available 24/7</span>
                </div>
              </div>
            </div>
            <div className="bg-slate-700 p-6 md:p-8 rounded-lg shadow-md">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-slate-950 text-white py-8 md:py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
            <div className="col-span-1 sm:col-span-2">
              <h3 className="text-xl font-bold mb-4">1028 Ventures</h3>
              <p className="text-slate-400">
                Professional call center solutions for businesses and organizations across all sectors.
              </p>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
              <ul className="space-y-2 text-slate-400">
                <li><a href="#services" className="hover:text-white transition-colors">Services</a></li>
                <li><a href="#contact" className="hover:text-white transition-colors">Contact</a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Connect</h4>
              <ul className="space-y-2 text-slate-400">
                <li><a href="#" className="hover:text-white transition-colors">LinkedIn</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Twitter</a></li>
              </ul>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-slate-800 text-center text-slate-400">
            <p>&copy; {new Date().getFullYear()} 1028 Ventures. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;