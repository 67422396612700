import React from 'react';
import { Quote } from 'lucide-react';

interface TestimonialProps {
  quote: string;
  author: string;
  role: string;
  company: string;
}

export default function Testimonial({ quote, author, role, company }: TestimonialProps) {
  return (
    <div className="bg-slate-800 p-6 md:p-8 rounded-lg shadow-md h-full border border-slate-700">
      <Quote className="w-8 h-8 text-indigo-400 mb-4" />
      <p className="text-slate-300 italic mb-4 text-sm md:text-base">{quote}</p>
      <div className="text-sm md:text-base">
        <p className="font-semibold text-white">{author}</p>
        <p className="text-slate-400">{role}, {company}</p>
      </div>
    </div>
  );
}