import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Phone, Mail, MessageSquare } from 'lucide-react';

interface FAQItem {
  question: string;
  answer: string;
}

const faqs: FAQItem[] = [
  {
    question: "What types of campaigns do you specialize in?",
    answer: "We specialize in high-volume outreach for industries such as political campaigns, healthcare, financial services, market research, and retail/e-commerce. From voter outreach and patient appointment reminders to customer support and product surveys, we tailor our services to fit each client's specific needs."
  },
  {
    question: "How quickly can you scale up agents for a campaign?",
    answer: "We can rapidly scale to meet your campaign's needs, often deploying additional agents within 24–48 hours, depending on volume requirements. For large-scale campaigns, we can assign hundreds of agents with minimal lead time, ensuring we're ready to support you when it matters most."
  },
  {
    question: "Are your agents trained in industry-specific regulations (e.g., HIPAA or TCPA)?",
    answer: "Yes, we ensure our agents are well-versed in relevant industry regulations. For healthcare clients, we maintain strict HIPAA compliance, and for telemarketing, we adhere to TCPA guidelines. We prioritize security and compliance across all services."
  },
  {
    question: "What is your pricing structure?",
    answer: "Our pricing varies by package, starting at $12/hour per agent for our Basic Package. We offer three main packages—Basic, Professional, and Enterprise—with rates adjusted for the scope and duration of the campaign. Custom packages are also available for unique needs."
  },
  {
    question: "Do you offer multi-channel support?",
    answer: "Absolutely! We provide support across phone, email, SMS, and chat channels. Multi-channel options are available in our Professional and Enterprise packages, ensuring we reach your audience through their preferred communication methods."
  },
  {
    question: "What's involved in getting started with your services?",
    answer: "Getting started is easy. After an initial consultation to understand your campaign goals, we'll create a customized plan, assign agents, and begin training if needed. Our setup process is efficient, allowing us to launch most campaigns within a few days."
  },
  {
    question: "Can I customize my package based on specific needs?",
    answer: "Yes, we offer flexible, customizable packages. Whether you need multilingual support, advanced analytics, or a specific number of agents, we can tailor a solution that aligns perfectly with your campaign requirements."
  },
  {
    question: "How do you ensure data security and client confidentiality?",
    answer: "We take data security and confidentiality seriously. Our agents follow strict data protection protocols, and we use secure systems to handle client information. We also comply with industry-specific regulations to protect sensitive data."
  },
  {
    question: "Do you provide reporting and analytics?",
    answer: "Yes, we offer regular reporting and analytics to give you insights into campaign performance. Our reports can include metrics like call completion rates, customer satisfaction scores, and lead conversion rates, helping you measure success and make informed decisions."
  },
  {
    question: "What payment options do you offer?",
    answer: "We accept major payment methods, including bank transfers and credit cards. For larger projects, we offer flexible billing arrangements and can discuss payment terms that work best for your budget and project timeline."
  },
  {
    question: "How do you handle seasonal or short-term campaigns?",
    answer: "We're experienced in managing seasonal and short-term campaigns, such as holiday retail support or election-related outreach. Our team can ramp up quickly and adapt to high call volumes, providing reliable service when you need it most."
  },
  {
    question: "What industries do you serve?",
    answer: "We serve various industries, including political campaigns, healthcare, financial services, market research, and retail/e-commerce. We understand the unique challenges each industry faces and tailor our approach to meet those needs."
  },
  {
    question: "Do you offer performance-based pricing options?",
    answer: "Yes, for certain campaigns we can structure pricing based on performance metrics, such as lead conversions or sales targets. This option is available for qualifying campaigns in specific industries. Contact us to discuss performance-based pricing."
  },
  {
    question: "How can I get a custom quote or consultation?",
    answer: "To get a custom quote, simply click the \"Get a Quote\" button on our website or contact us directly. We offer free consultations to discuss your needs, and we'll provide a tailored quote based on your requirements."
  }
];

const FAQItem: React.FC<{ item: FAQItem; isOpen: boolean; onClick: () => void }> = ({ item, isOpen, onClick }) => (
  <div className="border-b border-gray-200 last:border-0">
    <button
      className="w-full py-6 text-left focus:outline-none"
      onClick={onClick}
    >
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium text-gray-900">{item.question}</h3>
        {isOpen ? (
          <ChevronUp className="w-5 h-5 text-gray-500" />
        ) : (
          <ChevronDown className="w-5 h-5 text-gray-500" />
        )}
      </div>
      <div
        className={`mt-2 pr-12 text-gray-600 transition-all duration-300 ease-in-out overflow-hidden ${
          isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <p>{item.answer}</p>
      </div>
    </button>
  </div>
);

const FAQSection: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Frequently Asked Questions
          </h1>
          <p className="text-xl text-gray-600">
            Find answers to common questions about our call center services
          </p>
        </div>

        {/* Contact Methods */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300">
            <div className="flex items-center justify-center mb-4">
              <div className="p-3 bg-blue-100 rounded-full">
                <Phone className="w-6 h-6 text-blue-600" />
              </div>
            </div>
            <h3 className="text-lg font-semibold text-center mb-2">Phone Support</h3>
            <p className="text-gray-600 text-center">24/7 availability for your needs</p>
          </div>
          
          <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300">
            <div className="flex items-center justify-center mb-4">
              <div className="p-3 bg-blue-100 rounded-full">
                <Mail className="w-6 h-6 text-blue-600" />
              </div>
            </div>
            <h3 className="text-lg font-semibold text-center mb-2">Email Support</h3>
            <p className="text-gray-600 text-center">Quick response to inquiries</p>
          </div>
          
          <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300">
            <div className="flex items-center justify-center mb-4">
              <div className="p-3 bg-blue-100 rounded-full">
                <MessageSquare className="w-6 h-6 text-blue-600" />
              </div>
            </div>
            <h3 className="text-lg font-semibold text-center mb-2">Live Chat</h3>
            <p className="text-gray-600 text-center">Instant messaging support</p>
          </div>
        </div>

        {/* FAQ Items */}
        <div className="bg-white rounded-2xl shadow-lg">
          <div className="divide-y divide-gray-200">
            {faqs.map((faq, index) => (
              <FAQItem
                key={index}
                item={faq}
                isOpen={openIndex === index}
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              />
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="mt-16 text-center">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">
            Still have questions?
          </h2>
          <p className="text-gray-600 mb-8">
            We're here to help. Contact our team for more information.
          </p>
          <button className="bg-blue-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors duration-300">
            Get in Touch
          </button>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;