import React, { useState } from 'react';
import { Send } from 'lucide-react';

export default function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
    console.log('Form submitted:', formData);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-slate-300 mb-1">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="w-full px-4 py-2 text-base rounded-md border border-slate-600 bg-slate-800 text-white focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
          required
        />
      </div>
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-slate-300 mb-1">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="w-full px-4 py-2 text-base rounded-md border border-slate-600 bg-slate-800 text-white focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
          required
        />
      </div>
      <div>
        <label htmlFor="company" className="block text-sm font-medium text-slate-300 mb-1">Company</label>
        <input
          type="text"
          id="company"
          name="company"
          value={formData.company}
          onChange={handleChange}
          className="w-full px-4 py-2 text-base rounded-md border border-slate-600 bg-slate-800 text-white focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
          required
        />
      </div>
      <div>
        <label htmlFor="message" className="block text-sm font-medium text-slate-300 mb-1">Message</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          rows={4}
          className="w-full px-4 py-2 text-base rounded-md border border-slate-600 bg-slate-800 text-white focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
          required
        ></textarea>
      </div>
      <button
        type="submit"
        className="w-full sm:w-auto px-6 py-3 bg-indigo-600 text-white rounded-md font-medium inline-flex items-center justify-center hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
      >
        <Send className="w-5 h-5 mr-2" />
        Send Message
      </button>
    </form>
  );
}