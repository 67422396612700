import React from 'react';
import { Building2, Users, Shield, BarChart3, Phone, Award, Headphones } from 'lucide-react';

function ValueCard({ icon: Icon, title, description }: { icon: React.ElementType, title: string, description: string }) {
  return (
    <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow">
      <Icon className="w-8 h-8 text-blue-600 mb-4" />
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
}

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      {/* Hero Section */}
      <section className="relative bg-blue-700 text-white py-20">
        <div className="container mx-auto px-4 max-w-6xl">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">About VoiceReach Solutions</h1>
          <p className="text-xl md:text-2xl max-w-3xl leading-relaxed">
            Delivering excellence in every interaction, we provide scalable call center solutions 
            that drive measurable results across industries.
          </p>
        </div>
        <div className="absolute bottom-0 right-0 w-full h-16 bg-gradient-to-b from-transparent to-gray-50"></div>
      </section>

      {/* Mission & Story */}
      <section className="py-16 container mx-auto px-4 max-w-6xl">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl font-bold mb-6 text-gray-900">Our Story</h2>
            <p className="text-gray-700 leading-relaxed mb-6">
              Founded in 2015, VoiceReach Solutions emerged from a vision to transform how businesses 
              connect with their audiences. Our journey began with a small team dedicated to political 
              outreach, including our landmark collaboration with the DNC.
            </p>
            <p className="text-gray-700 leading-relaxed">
              Today, we've grown into a comprehensive call center solution provider, serving diverse 
              sectors while maintaining our commitment to excellence and personal touch that defined 
              our early days.
            </p>
          </div>
          <div className="relative">
            <img 
              src="https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
              alt="Team collaboration"
              className="rounded-lg shadow-xl"
            />
          </div>
        </div>
      </section>

      {/* Core Values */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4 max-w-6xl">
          <h2 className="text-3xl font-bold mb-12 text-center text-gray-900">Our Core Values</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <ValueCard
              icon={Users}
              title="Client-Centric Service"
              description="We put our clients first, tailoring solutions to match their unique goals and ensuring satisfaction at every step."
            />
            <ValueCard
              icon={BarChart3}
              title="Scalability & Flexibility"
              description="Our dynamic approach allows us to scale operations seamlessly, meeting your needs during peak times and special projects."
            />
            <ValueCard
              icon={Shield}
              title="Integrity & Compliance"
              description="We maintain strict adherence to industry regulations including HIPAA and TCPA, protecting your data and interests."
            />
          </div>
        </div>
      </section>

      {/* Expertise */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4 max-w-6xl">
          <h2 className="text-3xl font-bold mb-12 text-center text-gray-900">Why Choose Us</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-xl shadow-md">
              <Building2 className="w-8 h-8 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold mb-3">Industry Expertise</h3>
              <ul className="space-y-2 text-gray-600">
                <li>• Political Campaign Outreach</li>
                <li>• Healthcare Communications</li>
                <li>• Financial Services Support</li>
                <li>• Retail Customer Service</li>
              </ul>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-md">
              <Award className="w-8 h-8 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold mb-3">Proven Results</h3>
              <ul className="space-y-2 text-gray-600">
                <li>• 95% Client Satisfaction Rate</li>
                <li>• 24/7 Operational Capacity</li>
                <li>• Multi-language Support</li>
                <li>• Advanced Analytics & Reporting</li>
              </ul>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-md">
              <Headphones className="w-8 h-8 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold mb-3">Dedicated Support</h3>
              <ul className="space-y-2 text-gray-600">
                <li>• Personal Account Managers</li>
                <li>• Custom Solution Design</li>
                <li>• Quality Assurance</li>
                <li>• Continuous Training</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* CTA */}
      <section className="py-16 bg-blue-700 text-white">
        <div className="container mx-auto px-4 max-w-6xl text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Customer Experience?</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Let's discuss how our call center solutions can help you achieve your business goals.
          </p>
          <button className="bg-white text-blue-700 px-8 py-3 rounded-full font-semibold text-lg hover:bg-blue-50 transition-colors flex items-center mx-auto">
            <Phone className="w-5 h-5 mr-2" />
            Schedule a Consultation
          </button>
        </div>
      </section>
    </div>
  );
}

export default App;