import React, { useState } from 'react';
import { Check, Phone, Mail, MessageSquare, BarChart3, Users, Clock, Shield, Globe2 } from 'lucide-react';

function PricingCard({ 
  title, 
  price, 
  description, 
  features, 
  isPopular 
}: { 
  title: string; 
  price: number; 
  description: string; 
  features: string[];
  isPopular?: boolean;
}) {
  return (
    <div className={`relative flex flex-col p-8 rounded-2xl transition-all duration-300 ${
      isPopular 
        ? 'bg-gradient-to-br from-blue-600 to-blue-700 text-white shadow-xl scale-105' 
        : 'bg-white hover:shadow-xl'
    }`}>
      {isPopular && (
        <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-yellow-400 text-blue-900 text-sm font-semibold px-4 py-1 rounded-full">
          Most Popular
        </div>
      )}
      <h3 className={`text-2xl font-bold mb-2 ${isPopular ? 'text-white' : 'text-gray-900'}`}>
        {title}
      </h3>
      <div className="flex items-baseline mb-4">
        <span className={`text-4xl font-bold ${isPopular ? 'text-white' : 'text-gray-900'}`}>
          ${price}
        </span>
        <span className={`ml-1 text-lg ${isPopular ? 'text-blue-100' : 'text-gray-500'}`}>
          /hour per agent
        </span>
      </div>
      <p className={`mb-6 ${isPopular ? 'text-blue-100' : 'text-gray-600'}`}>
        {description}
      </p>
      <ul className="flex-grow space-y-4 mb-8">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <Check className={`w-5 h-5 mr-3 ${isPopular ? 'text-blue-200' : 'text-blue-500'}`} />
            <span className={isPopular ? 'text-blue-100' : 'text-gray-600'}>{feature}</span>
          </li>
        ))}
      </ul>
      <button
        className={`w-full py-3 px-6 rounded-lg font-semibold transition-all duration-300 ${
          isPopular
            ? 'bg-white text-blue-600 hover:bg-blue-50'
            : 'bg-blue-600 text-white hover:bg-blue-700'
        }`}
      >
        Get Started
      </button>
    </div>
  );
}

function AddonCard({ icon: Icon, title, price, description }: {
  icon: React.ElementType;
  title: string;
  price: number;
  description: string;
}) {
  return (
    <div className="bg-gray-50 p-6 rounded-xl hover:shadow-md transition-all duration-300">
      <Icon className="w-8 h-8 text-blue-600 mb-4" />
      <h4 className="text-lg font-semibold text-gray-900 mb-2">{title}</h4>
      <p className="text-gray-600 mb-3">{description}</p>
      <p className="font-semibold text-blue-600">+${price}/hour per agent</p>
    </div>
  );
}

function App() {
  const [showContactForm, setShowContactForm] = useState(false);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      {/* Header Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16 text-center">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          Transparent Pricing for Every Scale
        </h1>
        <p className="max-w-2xl mx-auto text-lg text-gray-600">
          Our flexible pricing is designed to scale with your needs, offering competitive rates 
          and transparent packages for businesses of all sizes. Choose the perfect solution for 
          your campaign requirements.
        </p>
      </div>

      {/* Main Pricing Cards */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-20">
        <div className="grid md:grid-cols-3 gap-8">
          <PricingCard
            title="Basic Package"
            price={12}
            description="Perfect for small-scale projects or initial testing phases."
            features={[
              "Standard customer support",
              "Basic data collection",
              "Phone and email support",
              "10-50 agents capacity",
              "8/5 support coverage"
            ]}
          />
          <PricingCard
            title="Professional Package"
            price={15}
            description="Ideal for medium-sized campaigns requiring enhanced features."
            features={[
              "Multi-channel support",
              "Basic reporting & analytics",
              "Priority agent availability",
              "Up to 100+ agent hours/week",
              "16/7 support coverage"
            ]}
            isPopular
          />
          <PricingCard
            title="Enterprise Package"
            price={20}
            description="Premium solution for large-scale, high-volume campaigns."
            features={[
              "Dedicated account management",
              "24/7 support coverage",
              "Custom reporting",
              "Compliance support (HIPAA)",
              "200+ agents capacity"
            ]}
          />
        </div>

        {/* Add-ons Section */}
        <div className="mt-20">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">
            Additional Services
          </h2>
          <div className="grid md:grid-cols-3 gap-6">
            <AddonCard
              icon={BarChart3}
              title="Advanced Analytics"
              price={3}
              description="Detailed insights, custom reports, and performance metrics."
            />
            <AddonCard
              icon={Globe2}
              title="Multilingual Support"
              price={2}
              description="Support in multiple languages with native speakers."
            />
            <AddonCard
              icon={Shield}
              title="Enhanced Security"
              price={4}
              description="Additional security measures and compliance protocols."
            />
          </div>
        </div>

        {/* CTA Section */}
        <div className="mt-20 text-center bg-blue-600 rounded-2xl p-12 text-white">
          <h2 className="text-3xl font-bold mb-4">Need a Custom Solution?</h2>
          <p className="text-blue-100 mb-8 max-w-2xl mx-auto">
            Our team can create a tailored package based on your specific requirements. 
            Get in touch for a personalized quote and free consultation.
          </p>
          <button 
            onClick={() => setShowContactForm(true)}
            className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors duration-300"
          >
            Schedule a Consultation
          </button>
        </div>

        {/* Fine Print */}
        <div className="mt-12 text-center text-sm text-gray-500">
          <p>* Minimum contract terms may apply. Volume discounts available for long-term commitments.</p>
          <p>* All prices are in USD. Billing occurs monthly based on actual hours utilized.</p>
        </div>
      </div>
    </div>
  );
}

export default App;